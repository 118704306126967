@import '/node_modules/sass-rem/_rem.scss';
@import '/node_modules/@appkit4/styles/scss/variables';

body {
  --spacing-2-unit: 2rem;
  --header-height: 64px;
  --height-83: 83px;
}

// [data-mode='light'],
// [data-mode='light'] ::before,
// [data-mode='light'] ::after {
// }

// [data-mode='dark'],
// [data-mode='dark'] ::before,
// [data-mode='dark'] ::after {
// }

[data-mode='dark'] .pre-login-container {
  .left-part {
    .login-logo {
      background-image: url(../assets/images/bkc-logo-pwc-white.svg) !important;
    }
  }
}

.section-hide {
  display: none;
}

.required-dot-left {
  position: relative;
}

.required-dot-left::before {
  content: '';
  position: absolute;
  top: $spacing-3;
  left: calc(-1 * $spacing-3);
  width: 0.25rem;
  height: 0.25rem;
  border-radius: $border-radius-1;
  background-color: $color-text-error;
  transition: all 0.3s;
}

.required-dot-right {
  position: relative;
}

.required-dot-right::before {
  content: '';
  position: absolute;
  top: $spacing-1;
  right: calc(-1 * $spacing-3);
  width: 0.25rem;
  height: 0.25rem;
  border-radius: $border-radius-1;
  background-color: $color-text-error;
  transition: all 0.3s;
}

.customer-detail-container {
  .ap-panel-body {
    padding: $spacing-6;
  }

  .ap-grid {
    margin: unset;
  }

  .customer-detail-info-card,
  .customer-detail-edit-card {
    .ap-panel-body {
      display: flex;
      flex-direction: column;
      gap: $spacing-5;
    }
  }
}

.no-grid-margin.ap-grid {
  margin: unset;
}

.ap-field-validation-error {
  color: $color-text-error;
}

.ap-field-validation-success {
  color: $states-success-04;
}

.show-cogs-text {
  margin-right: $spacing-3;
}

.ap-field-validation-error,
.address-remind-info {
  margin: $spacing-3 0 $spacing-3 $spacing-3;
}

.cus-header-container .ap-breadcrumb ol {
  margin-top: $spacing-7;
  margin-bottom: $spacing-5;
}

.tool-tip-style {
  white-space: pre-line;
}

.ap-option-item.disabled .ap-option-desc {
  opacity: var(--opacity-5);
}

.compact-dropdown.ap-dropdown {
  .ap-field .ap-field-container .ap-field-wrapper {
    width: $height-9 !important;
    height: $spacing-32 !important;
  }

  .ap-dropdown-select .icon-down-chevron-outline {
    top: -0.5rem !important;
    right: -0.5rem !important;
  }

  .ap-dropdown-list {
    top: 2.5rem;
  }

  .ap-field:not(.has-length):not(:focus-within)
    .ap-field-container:not(.hide-title)
    input.ap-field-input {
    margin-top: 0 !important;
    padding: 0 !important;
  }
}

.cus-tab.ap-tabset-container .ap-tabset-toggle-wrapper.filled {
  background-color: $color-background-container !important;
}

.detail-container {
  .ap-panel-body {
    padding: $spacing-6;
    padding-bottom: $spacing-2-unit;
  }
}

.ap-buttons-wrapper .ap-button-dropdown {
  max-height: 17.5rem;
  overflow: auto;
}

.ap-text-editor-container {
  border: 1px solid $color-background-border;
}

ap-search {
  .no-padding.ap-search-container {
    padding: 0;
  }
}

.skip-link {
  width: rem(193px);
  height: $height-5;
  border-radius: $border-radius-2;
  letter-spacing: $letter-spacing-1;
  font-weight: $font-weight-2;
  font-size: $typography-text-size-3;
  color: $color-text-tertiary;
  left: rem(300px);
  position: absolute;
  top: rem(12px);
  z-index: -1;
  text-align: center;
  line-height: $typography-line-height-40;
  opacity: 0;
  background-color: none;

  &.skip-link-pc:focus {
    box-shadow: 0 0 0 rem(2px) $data-blue !important;
    background-color: $color-background-container;
    padding: rem(1px);
    outline: none;

    span {
      position: absolute;
      width: rem(189px);
      top: $spacing-1;
      left: $spacing-1;
      background-color: $color-background-primary;
      line-height: $typography-line-height-6;
      border-radius: $border-radius-2;
    }

    &:hover {
      padding: $spacing-1;
      background-color: $color-background-container !important;

      span {
        background-color: $color-background-primary;
      }
    }
  }

  &:focus {
    z-index: 999;
    background-color: $data-orange;
    opacity: 1;
  }

  &:hover {
    text-decoration: none;
    color: $color-text-tertiary;
    background-color: $color-background-primary;
    cursor: pointer;
  }
}

// For ap-panel
.ap-panel.no-shadow {
  box-shadow: unset;
}

// For dropdown
.doubleLine {
  .ap-option-item {
    display: flex !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    justify-content: center !important;
    height: 4.5rem !important;

    .ap-option-left {
      max-width: 100%;
    }
  }
}

.ap-modal-wrapper .ap-modal-footer {
  overflow: unset;
}

.ap-modal-wrapper .ap-single-dropdown-list {
  scrollbar-width: thin;
}

.table-empty-panel .wrapper {
  height: 10 * $height-15 - $height-2;
}

.table-header-wrapper {
  .ap-checkbox + .ap-checkbox-label {
    color: var(--color-text-heading);
  }
}

.checkbox-cell {
  .ap-checkbox + .ap-checkbox-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ap-checkbox {
    flex-shrink: 0;
  }
}

.no-gap .ap-option-item.noResult {
  display: none;
}

.no-gap .ap-dropdown-list.open {
  .ap-option-item.noResult {
    display: block;
  }
}

.ap-modal-wrapper {
  .show-scrollbar,
  .ap-combobox-dropdown-list,
  .ap-multiple-dropdown-list {
    scrollbar-width: thin;
  }
  &.show:has(.file-modal) {
    overflow: hidden;
  }
}

.standard-search-bar {
  width: calc(304 / 1080 * 100%);
  max-width: 304px;
}

.ap-tooltip-text {
  white-space: pre-line;
}

app-search-dropdown.search-container {
  width: rem(304px);
}

.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  width: 100%;
}

.notes-panel,
.comments-panel {
  mark {
    background-color: $primary-blue-01;
    color: $color-text-body;
  }
}

.full-divider {
  border-top: $border-1 $border-type-1 $color-background-border;
}

@for $i from 2 through 5 {
  $marginLeftSize: 10px * $i - 5px;

  .text-position-#{$i} {
    margin-left: $marginLeftSize;
    max-width: 284px - $marginLeftSize;
  }
}

.text-position-1 {
  margin-left: 10px;
  max-width: 284px;
}

.display-hide .ap-option-left {
  display: none !important;
}

.avatar-list-box {
  &.bookkeeper-avatar-list,
  &.customer-avatar-list {
    width: 344px !important;
    padding: var(--spacing-6) var(--spacing-4) !important;
  }
}

#customerGroupModal {
  position: relative;
  z-index: 99;
}

#sendFeedbackPanel {
  position: relative;
  z-index: 999;
}